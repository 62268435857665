<template>
	<div
		class="subscription-item"
		v-if="subscriptionItems.length > 0"
	>
		<div class="user">
			<div>{{ initials }}</div>
		</div>
		<div class="remove-button">
			<TnButton
				size="xs"
				tertiary
				:loading="removing"
				:disabled="!!error"
				icon-right="trashcan"
				style="margin-right: -16px; margin-top: -5px"
				@click="remove"
			>
				Fjern
			</TnButton>
		</div>
		<div class="user-info">
			<h3>{{ userInfo.firstName }}</h3>
			<p>{{ userInfo.number }}</p>
		</div>
		<div
			class="old-subscription"
			v-if="!portIn"
		>
			<div v-if="loading">
				<TnSkeleton
					height="18"
					border-radius="s"
					width="60"
					style="margin-bottom: 4px"
				/>
				<TnSkeleton
					height="18"
					border-radius="s"
					width="65"
				/>
			</div>
			<div v-else>
				<h3>
					{{ !newSubscription && !keepSubscription ? "Endres fra" : currentSubscriptionDetails.header }}
				</h3>
				<p>{{ currentSubscriptionDetails.subscription }}</p>
			</div>
		</div>
		<div
			class="old-subscription-price"
			v-if="!portIn"
		>
			<div v-if="loading">
				<TnSkeleton
					height="20"
					border-radius="s"
					width="60"
				/>
			</div>
			<div v-else>
				<p
					:class="{ 'line-through': newSubscriptionDetails?.salePrice }"
					v-if="!changeSubscription"
				>
					{{ newSubscriptionDetails?.price }}
				</p>
				<p v-if="!changeSubscription">
					{{ newSubscriptionDetails?.salePrice }}
				</p>
			</div>
		</div>
		<div class="new-subscription">
			<h3 v-if="!portIn && !keepSubscription">
				{{ newSubscription ? currentSubscriptionDetails.header : newSubscriptionDetails.header }}
			</h3>
			<h3 v-if="portIn && !keepSubscription">Nytt abonnement</h3>
			<div v-if="!keepSubscription">{{ newSubscriptionDetails.subscription }}</div>
			<div v-if="oneTimePrice && !tryggStart">
				<br />
				<span class="establishment-fee">Etableringsgebyr: {{ newSubscriptionDetails.oneTimePrice }}</span>
			</div>
		</div>
		<div class="new-subscription-price">
			<p
				:class="{ 'line-through': !keepSubscription || newSubscriptionDetails?.salePrice }"
				v-if="newSubscriptionDetails?.salePrice"
			>
				{{ newSubscriptionDetails.price }}
			</p>
			<p v-if="newSubscriptionDetails.price && !keepSubscription">
				{{ newSubscriptionDetails?.salePrice || newSubscriptionDetails.price }}
			</p>
		</div>
	</div>
</template>
<script>
import { mapGetters, mapState } from "vuex";
import { formatPrice } from "~/helpers/formatting/price";
export default defineNuxtComponent({
	name: "ShoppingCartCardSubscriptionItem",

	props: {
		subscriptionItems: {
			type: Array,
			default: () => [],
		},
	},

	data() {
		return {
			removing: false,
			error: false,
		};
	},

	computed: {
		...mapGetters("checkout", ["terminals", "hasUnconfiguredTerminals"]),
		...mapGetters("shoppingCart", ["subscriptions"]),
		...mapState("shoppingCart", ["loading"]),
		isMobile() {
			return window.screen.availWidth <= 767;
		},
		newSubscriptionPrice() {
			/* Finds matching msisdn in subscriptions(shopping-cart store) from validate call and returns validated "newPrice"
			 */
			return this.subscriptions?.find(
				(subscription) =>
					subscription?.msisdn ===
					this.subscriptionItems
						.find((item) => item.type === "msisdn")
						?.metadata?.msisdnDetails?.msisdn?.replace(/^47(.{8,})$/, "$1"),
			)?.newPriceFull;
		},
		initials() {
			if (this.userInfo.firstName && this.userInfo.lastName) {
				return this.userInfo.firstName.slice(0, 1) + this.userInfo.lastName.slice(0, 1);
			} else {
				return "NN";
			}
		},
		userInfo() {
			const details = this.subscriptionItems.filter((x) => x.type === "msisdn");
			function sliceNumber(number) {
				return number.slice(2, 5) + " " + number.slice(5, 7) + " " + number.slice(7, 10);
			}
			return {
				firstName:
					details[0]?.metadata?.msisdnDetails?.user?.firstName?.split(" ")[0] ||
					details[0]?.metadata?.msisdnDetails?.user?.given_name?.split(" ")[0] ||
					undefined,
				lastName:
					details[0]?.metadata?.msisdnDetails?.user?.lastName ||
					details[0]?.metadata?.msisdnDetails?.user?.family_name ||
					undefined,
				number: details[0]?.metadata?.msisdnDetails?.msisdn
					? sliceNumber(details[0]?.metadata.msisdnDetails.msisdn)
					: undefined,
			};
		},
		currentSubscriptionDetails() {
			const details = this.subscriptionItems.filter((x) => x.type === "msisdn");
			let price;
			if (
				details[0]?.metadata?.msisdnDetails?.price?.campaigns
					?.filter((x) => x.isSubscribed)
					?.filter((x) => x.prices?.length > 0)?.length > 0
			) {
				price = details[0]?.metadata?.msisdnDetails?.price?.prices?.filter((x) => x.type === "MONTHLY_ACTUAL_PRICE")[0]
					?.price;
			} else {
				price = details[0]?.metadata?.msisdnDetails?.price?.prices?.filter((x) => x.type === "MONTHLY_LIST_PRICE")[0]
					?.price;
			}
			const keepingExistingSubscription = details?.[0]?.metadata?.name === "Beholder";
			return {
				header: details[0]?.metadata?.name,
				subscription: details[0]?.metadata?.msisdnDetails?.currentSubscriptionName,
				price: keepingExistingSubscription ? undefined : formatPrice.monthly(price),
			};
		},
		newSubscriptionDetails() {
			const subscription = this.subscriptionItems.filter((x) => x.type === "subscription");
			const details = {
				header: "Endres til",
				subscription: subscription[0]?.metadata?.name || undefined,
				price: this.newSubscriptionPrice?.originalPrice
					? formatPrice.monthly(this.newSubscriptionPrice?.originalPrice)
					: subscription[0]?.metadata?.recurringPrice !== undefined
						? formatPrice.monthly(subscription[0]?.metadata?.recurringPrice)
						: undefined,
				oneTimePrice: formatPrice.oneTime(subscription[0]?.metadata?.oneTimeFee),
				salePrice: this.newSubscriptionPrice?.discountedPrice
					? formatPrice.monthly(this.newSubscriptionPrice?.discountedPrice)
					: undefined,
			};
			if (details?.salePrice === details?.price) {
				details.salePrice = undefined;
			}
			return details;
		},
		keepSubscription() {
			return (
				this.currentSubscriptionDetails?.subscription === this.newSubscriptionDetails?.subscription ||
				(!this.newSubscriptionDetails?.subscription === undefined &&
					this.currentSubscriptionDetails.header === "Beholder:")
			);
		},
		newSubscription() {
			return this.currentSubscriptionDetails?.header === "Nytt abonnement";
		},
		changeSubscription() {
			return this.newSubscriptionDetails?.subscription !== undefined && !this.keepSubscription;
		},
		oneTimePrice() {
			return (
				parseInt(this.newSubscriptionDetails?.oneTimePrice) > 0 &&
				this.currentSubscriptionDetails.header === "Nytt abonnement"
			);
		},
		portIn() {
			return !this.currentSubscriptionDetails?.subscription;
		},
		tryggStart() {
			return this.newSubscriptionDetails?.subscription?.toLowerCase()?.includes("trygg start");
		},
	},

	methods: {
		async remove() {
			try {
				this.removing = true;
				const subscriptionItems = this.terminals[0]?.subItems?.filter(
					(subItem) => subItem.type === "msisdn" || subItem.type === "subscription" || subItem.type === "agreement",
				);
				for (const item of subscriptionItems) {
					await this.$store.dispatch("shoppingCart/remove", item.id);
				}
				if (this.hasUnconfiguredTerminals) {
					this.$router.push(`/checkout/terminal/identification.jsp`);
				}
			} catch (err) {
				console.error(err);
				this.error = err;
			} finally {
				this.removing = false;
			}
		},
	},
});
</script>
<style lang="scss" scoped>
.subscription-item {
	display: grid;
	grid-template: "user user-info remove-button" 1fr "user old-subscription old-subscription-price" auto "user new-subscription new-subscription-price" auto / 3fr 8fr 9fr;
	gap: $spacing-s 0;
	grid-auto-flow: row;
}

.remove-button,
.old-subscription-price,
.new-subscription-price {
	display: flex;
	justify-content: flex-end;
}

.user {
	width: 75px;
	height: 70px;
	grid-area: user;
	margin-right: $spacing-m;
}

.remove-button {
	grid-area: remove-button;
}

.user-info {
	grid-area: user-info;
}

.old-subscription {
	grid-area: old-subscription;
}

.old-subscription-price {
	grid-area: old-subscription-price;
}

.line-through {
	color: $color-neutrals-600-shade;
	text-decoration: line-through;
}

.new-subscription {
	grid-area: new-subscription;
}

.new-subscription-price {
	grid-area: new-subscription-price;
	display: flex;
	justify-content: flex-start;
	flex-direction: column;
	align-items: flex-end;
}

.user {
	& > div {
		width: 50px;
		height: 50px;
		margin-top: -6px;
		background-color: $color-neutrals-50-tint;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;

		@include font-text-bold-xl;
	}
}

h3 {
	@include font-text-bold-xs;
}
</style>
