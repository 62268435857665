<template>
	<div class="shopping-cart-summary">
		<h2>Din handlekurv</h2>
		<ShoppingCartCard
			v-for="(item, i) in shoppingCartItems"
			:key="i"
			:card-items="item.type !== 'swapDevice' ? item.value : undefined"
			:swapDevice="item.type === 'swapDevice' ? item.value : undefined"
			:type="item.type"
		/>
		<ShoppingCartCard
			v-if="subscriptionInCart"
			:subscriptionItems="subscriptionItems"
		/>
		<!--<ECommerceRecommendedAccessory shoppingCartComponent/>-->
		<PaymentSummary
			showSubItems
			shoppingCart
		/>
	</div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
export default defineNuxtComponent({
	name: "ShoppingCartSummary",
	props: ["heading"],

	computed: {
		...mapState("shoppingCart", ["loading"]),
		...mapGetters("shoppingCart", ["visibleCartItems"]),
		unprocessedItems() {
			return this.visibleCartItems?.filter((item) => !item?.metadata?.processed);
		},
		shoppingCartItems() {
			return [
				{ type: "mainItem", value: [{ ...this.mainItem, mainItem: true }] },
				{ type: "swapDevice", value: this.swapDevice },
				{
					type: "hardwareItems",
					value:
						this.mainItem?.subItems?.filter((item) => item.type === "hardware") ||
						this.visibleCartItems?.filter((item) => item.type === "hardware") ||
						undefined,
				},
				{
					type: "agreementItems",
					value: this.mainItem?.subItems?.filter((item) => item.type === "agreement") || undefined,
				},
			].filter((item) =>
				item.type === "swapDevice"
					? item.value !== undefined
					: item.type === "mainItem"
						? item.value?.[0]?.metadata !== undefined
						: item.value?.length > 0,
			);
		},
		mainItem() {
			return this.visibleCartItems.filter(
				(x) => x.type !== "old-swap-device" && !["Lyd", "Tilbehør", "Ruter"].includes(x.metadata?.category),
			)?.[0];
		},
		onlySwapDevice() {
			return this.visibleCartItems.filter((x) => x.type === "old-swap-device")?.[0]?.metaData.hardware;
		},
		connectedSwapDevice() {
			return this.mainItem?.metadata?.swap?.tradein?.phoneData;
		},
		swapDevice() {
			return this.onlySwapDevice || this.connectedSwapDevice;
		},
		hardwareItems() {
			return this.visibleCartItems[0]?.subItems?.filter((item) => item.type === "hardware");
		},
		subscriptionItems() {
			return this.visibleCartItems[0]?.subItems?.filter(
				(item) => item.type === "subscription" || item.type === "msisdn",
			);
		},
		subscriptionInCart() {
			return typeof this.subscriptionItems === "object" && this.subscriptionItems.length > 0;
		},
		agreementItems() {
			return this.visibleCartItems[0]?.subItems?.filter((item) => item.type === "agreement");
		},
	},
});
</script>

<style lang="scss" scoped>
.shopping-cart-summary {
	padding: 0 1px;
	margin-bottom: -$spacing-m;
	color: $color-text;

	@include font-text-xs;

	@include breakpoint(mobile) {
		margin-top: -$spacing-l;
	}
}

h2 {
	@include font-title-s;
}
</style>
