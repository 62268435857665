<template>
	<ShoppingCartCardItemLayout>
		<template #badge>
			<TnBadge
				responsive
				compact
				size="m"
				class="badge"
				v-if="product.metadata?.badge"
				>{{ product.metadata?.badge }}</TnBadge
			>
		</template>

		<template #product-image>
			<ShoppingCartImage
				class="product-image"
				:product="product"
			/>
		</template>

		<template #product-title>
			<template v-if="ecommerceUrl">
				<a
					class="product-title"
					:href="ecommerceUrl"
					:aria-label="`Link to ${product?.metadata?.name} product page`"
				>
					{{ product.metadata.name }}
				</a>
			</template>
			<template v-else>
				<p class="product-title">
					{{ product.metadata.name }}
				</p>
			</template>
			<p v-if="product.metadata?.campaign?.name">{{ product.metadata?.campaign?.name }}</p>
		</template>

		<template #product-info>
			<div class="product-info">
				{{ productInfo }}
			</div>
		</template>

		<template #product-price>
			<div class="product-price">
				{{ productPrice.currentPrice }}
			</div>
		</template>

		<template #product-discounted-price>
			<div
				class="product-discounted-price"
				v-if="productPrice.priceBeforeDiscount"
			>
				{{ `(${productPrice.priceBeforeDiscount})` }}
			</div>
		</template>

		<template #remove-button>
			<div class="remove-button">
				<TnButton
					v-if="!disableRemove"
					size="xs"
					tertiary
					:loading="removing"
					:disabled="removing"
					icon-right="trashcan"
					style="margin-right: -16px; margin-top: -5px"
					@click="initiateRemove"
					:aria-label="`Fjern ${product?.metadata?.name} fra handlekurven`"
				>
					Fjern
				</TnButton>
			</div>
		</template>

		<template
			#product-additional-info
			v-if="showAdditionalInfo"
		>
			<div class="product-additional-info">
				<p>
					Totalpris etter 24 md. er {{ productPrice.totalPrice }}
					<br />
					<span>Abonnement kommer i tillegg</span>
				</p>
			</div>
		</template>
		<template #error>
			<TnNotificationInline
				v-if="error"
				timeout="2"
				size="s"
				type="error"
				position="bottom-right"
				aria-live="assertive"
				:aria-label="`Error: ${product?.metadata?.name} could not be removed from the shopping cart`"
			>
				Det oppsto et problem med å fjerne produktet fra handlekurven, vennligst prøv igjen.
			</TnNotificationInline>
		</template>
		<template #modal>
			<ECommerceModal
				:modal="modal"
				:loading="removing"
				:disabled="error"
				@confirmPrompt="remove"
				@closeModal="modal = !modal"
			/>
		</template>
	</ShoppingCartCardItemLayout>
</template>
<script>
import { mapGetters, mapState } from "vuex";
import { formatPrice } from "~/helpers/formatting/price";
export default defineNuxtComponent({
	name: "ShoppingCartCardProducts",
	props: ["product"],

	data() {
		return {
			error: false,
			removing: false,
			modal: false,
		};
	},

	computed: {
		...mapGetters("shoppingCart", ["isVatExempt", "eligibleMemberPrice"]),
		...mapState("shoppingCart", ["selectedShoppingCartOption", "items"]),
		...mapGetters("checkout", ["validSubscription"]),
		isDevice() {
			return ["mobiltelefoner", "god som ny", "smartklokker", "nettbrett"].includes(
				this.product?.metadata?.category?.toLowerCase(),
			);
		},
		disableRemove() {
			return this.product.metadata.price === 0 && this.product.metadata.recurringPrice === 0;
		},
		ecommerceUrl() {
			return this.product?.metadata?.link?.to;
		},
		showAdditionalInfo() {
			const hasSwap = !!this.product?.mainItem && !!this.product.metadata?.swap;
			const hasDownPayment = this.product?.metadata?.downPayment && this.product.metadata?.prices.downPaymentPrice;
			return !!hasSwap || !!hasDownPayment;
		},
		productPrice() {
			if (
				// If the product is a main item, has a full price OR no SWAP, and has a member price with a subscription
				this.product?.mainItem === true &&
				(this.product.metadata?.fullPrice || !this.product.metadata?.swap) &&
				this.eligibleMemberPrice?.memberPrice &&
				this.items.some((item) => item.subItems?.some((subItem) => subItem.type === "subscription"))
			) {
				return {
					currentPrice: formatPrice.oneTime(this.eligibleMemberPrice.memberPrice, { round: true }),
					priceBeforeDiscount: formatPrice.oneTime(this.eligibleMemberPrice.normalPrice, { round: true }),
				};
			}
			if (
				// Main items with downPaymentPrice
				this.product?.mainItem === true &&
				this.product.metadata?.downPayment &&
				this.product.metadata?.prices?.downPaymentPrice
			) {
				const discountedPrice = this.product.metadata?.prices?.discountedDownPaymentPrice || undefined;
				return {
					currentPrice: this.isVatExempt
						? formatPrice.oneTime(
								this.product.metadata.prices?.discountedDownPaymentPriceWithoutVat ||
									this.product.metadata.prices.downPaymentPriceWithoutVat,
								{ round: true },
							)
						: formatPrice.monthly(
								this.product.metadata.prices?.discountedDownPaymentPrice ||
									this.product.metadata.prices.downPaymentPrice,
								{ round: true },
							),
					totalPrice: this.isVatExempt
						? formatPrice.oneTime(
								this.product.metadata.prices?.discountedDownPaymentPriceWithoutVat * 24 ||
									this.product.metadata.prices?.downPaymentPriceWithoutVat * 24,
								{ round: true },
							)
						: formatPrice.oneTime(
								this.product.metadata.prices?.discountedDownPaymentPrice * 24 ||
									this.product.metadata.prices?.downPaymentPrice * 24,
								{ round: true },
							),
					priceBeforeDiscount: discountedPrice
						? this.isVatExempt
							? formatPrice.oneTime(this.product.metadata.prices.downPaymentPriceWithoutVat, { round: true })
							: formatPrice.oneTime(this.product.metadata.prices.downPaymentPrice, { round: true })
						: undefined,
				};
			}
			if (
				// If the product has a recurring price and price is 0 or has SWAP
				this.product.metadata?.recurringPrice &&
				(this.product.metadata?.price === 0 || this.product.metadata?.swap)
			) {
				const productPrice = this.product.metadata?.recurringPrice;
				const discountedPrice = this.product.metadata?.saleRecurringPrice || undefined;
				return {
					currentPrice: discountedPrice
						? formatPrice.monthly(discountedPrice, { round: true })
						: formatPrice.monthly(productPrice, { round: true }),
					priceBeforeDiscount: discountedPrice ? formatPrice.monthly(productPrice, { round: true }) : undefined,
					totalPrice: discountedPrice
						? formatPrice.oneTime(this.product.metadata?.swapDiscountedTotalPrice, { round: true })
						: formatPrice.oneTime(this.product.metadata?.swapTotalPrice, { round: true }),
				};
			}
			const productPrice = this.isVatExempt ? this.product.metadata?.priceWithoutVat : this.product.metadata?.price;
			const discountedPrice = this.isVatExempt
				? this.product.metadata?.salePriceWithoutVat
				: this.product.metadata?.salePrice || undefined;
			return {
				currentPrice: discountedPrice
					? formatPrice.oneTime(discountedPrice, { round: true })
					: formatPrice.oneTime(productPrice, { round: true }),
				priceBeforeDiscount: discountedPrice ? formatPrice.oneTime(productPrice, { round: true }) : undefined,
			};
		},
		productInfo() {
			const productColor = this.product?.metadata?.color;
			const productMemory = this.product?.metadata?.memory;
			return productMemory ? productColor + ", " + productMemory : productColor;
		},
	},

	methods: {
		initiateRemove() {
			if (this.isDevice) {
				this.modal = true;
			} else {
				this.remove();
			}
		},
		async remove() {
			try {
				this.error = false;
				this.removing = true;
				await this.$store.dispatch("shoppingCart/remove", this.product.id);
			} catch (err) {
				console.error(err);
				this.error = true;
			} finally {
				this.removing = false;
			}
		},
	},
});
</script>
<style lang="scss" scoped>
.product-title {
	margin-top: $spacing-xs;
	height: fit-content;
	text-decoration: none;
	color: inherit;

	@include font-text-bold-xs;
}

.product-info {
	margin-bottom: $spacing-xs;

	@include font-text-xs;
}

.product-price,
.product-discounted-price,
.remove-button {
	display: flex;
	justify-content: flex-end;
}

.product-price {
	@include font-text-s;
}

.product-discounted-price {
	color: $color-neutrals-600-shade;
	text-decoration: line-through;

	@include font-text-xs;
}

.product-additional-info {
	color: $color-neutrals-600-shade;
	font-style: italic;
	margin-top: $spacing-l;

	@include font-text-xs;
}

.badge {
	margin-top: -$spacing-m;
	margin-bottom: $spacing-m;
	width: fit-content;
}

.modal-content {
	display: flex;
	flex-direction: column;
	gap: $spacing-xl;

	@include font-text-s;

	h3 {
		@include font-title-xs;
	}

	&-texts {
		display: flex;
		flex-direction: column;
		gap: $spacing-xs;
	}

	&-buttons {
		display: flex;
		flex-direction: column;
		align-self: center;
		gap: $spacing-m;
		width: 80%;
	}
}
</style>
