<template>
	<div class="shopping-cart-item-layout">
		<div class="badge-container">
			<slot name="badge"></slot>
		</div>

		<div class="product-image-container">
			<slot name="product-image"></slot>
		</div>

		<div class="product-title-container">
			<slot name="product-title"></slot>
		</div>

		<div class="product-info-container">
			<slot name="product-info"></slot>
		</div>

		<div class="product-price-container">
			<slot name="product-price"></slot>
		</div>

		<div class="product-discounted-price-container">
			<slot name="product-discounted-price"></slot>
		</div>

		<div class="remove-button-container">
			<slot name="remove-button"></slot>
		</div>

		<div class="product-additional-info-container">
			<slot name="product-additional-info"></slot>
		</div>

		<div class="error-container">
			<slot name="error"></slot>
		</div>

		<slot name="modal"></slot>
	</div>
</template>
<script>
export default defineNuxtComponent({
	name: "ShoppingCartCardItemLayout",
});
</script>
<style lang="scss" scoped>
.shopping-cart-item-layout {
	display: grid;
	grid-template: "badge badge ." auto "product-image product-title product-price" auto "product-image product-title product-discounted-price" 1fr "product-image product-info remove-button" auto "product-additional-info product-additional-info product-additional-info" auto "error error error" auto / 3fr 8fr 9fr;
	gap: 0;

	a:focus-visible {
		outline: 2px solid $color-cta-default;
		border-radius: $spacing-m;
		outline-offset: 3px;
	}
}

.product-image-container {
	grid-area: product-image;
}

.product-title-container {
	grid-area: product-title;
}

.product-info-container {
	grid-area: product-info;
}

.product-price-container {
	grid-area: product-price;
}

.product-discounted-price-container {
	grid-area: product-discounted-price;
}

.remove-button-container {
	grid-area: remove-button;
}

.product-additional-info-container {
	grid-area: product-additional-info;
}

.badge-container {
	grid-area: badge;
}

.error-container {
	grid-area: error;
}
</style>
