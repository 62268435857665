<template>
	<ShoppingCartCardItemLayout class="swap-device">
		<template #badge>
			<p class="swap-header">Mobilen du bytter inn</p>
		</template>
		<template #product-image>
			<ShoppingCartImage
				class="image-container"
				:swapDevice="swapDevice"
			/>
		</template>
		<template #product-title>
			<p class="title">{{ swapDevice.model }}</p>
		</template>
		<template #product-additional-info>
			<p class="additionalInfo">Mobilen sendes i retur etter at du mottar din nye mobil. Konvolutt følger med.</p>
		</template>
		<template #remove-button>
			<div class="remove">
				<TnButton
					size="xs"
					tertiary
					icon-right="trashcan"
					@click="remove"
				>
					Fjern
				</TnButton>
			</div>
		</template>
	</ShoppingCartCardItemLayout>
</template>
<script>
import { mapActions, mapState } from "vuex";
export default defineNuxtComponent({
	name: "ShoppingCartCardSwapDevice",

	props: {
		swapDevice: {
			type: Object,
			required: true,
		},
	},

	computed: {
		...mapState("shoppingCart", ["items"]),
	},

	methods: {
		...mapActions("tradein", ["resetTradein"]),
		...mapActions("shoppingCart", ["emptyCart"]),

		remove() {
			if (this.items.some((x) => x.type !== "old-swap-device")) {
				this.resetTradein();
			} else {
				this.emptyCart();
				this.$router.push("/");
			}
		},
	},
});
</script>
<style lang="scss" scoped>
.swap-device {
	.title {
		margin-top: $spacing-xs;

		@include font-text-bold-xs;
	}

	.additionalInfo {
		color: $color-neutrals-600-shade;
		margin-top: $spacing-l;

		@include font-text-s;
	}

	.remove {
		display: flex;
		justify-content: flex-end;
		margin-top: -$spacing-xs;
		margin-right: -$spacing-m;
	}

	.swap-header {
		margin-bottom: $spacing-m;

		@include font-text-bold-s;
	}
}
</style>
