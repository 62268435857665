<template>
	<div class="product-image">
		<a
			v-if="ecommerceUrl"
			:href="ecommerceUrl"
			aria-label="link-to-product-page"
		>
			<img
				@load="imageLoading = false"
				v-if="imageSrc"
				:style="imageLoading ? 'display: none' : 'display: block'"
				:class="{ 'product-card-product-img': true, loaded: !imageLoading }"
				:alt="product.metadata.name"
				:src="imageSrc"
			/>
		</a>
		<Component
			v-else-if="agreement"
			:is="agreement"
			style="width: 50px; height: 50px"
			alt="forsikring"
		/>
		<img
			@load="imageLoading = false"
			v-if="swapDevice?.imageUrl"
			:style="imageLoading ? 'display: none' : 'display: block'"
			:class="{ 'product-card-product-img': true, loaded: !imageLoading }"
			:src="swapDevice?.imageUrl"
			:alt="swapDevice?.model"
		/>
		<TnSkeleton
			v-if="imageLoading && !agreement && imageSrc"
			width="100%"
			height="100%"
			border-radius="s"
		/>
		<AllFallbackPhonePlan v-else-if="!agreement && !imageSrc && swapDevice && !swapDevice?.imageUrl" />
	</div>
</template>
<script>
import AllFallbackPhonePlan from "@/assets/product-icons-svg/svg/AllFallbackPhonePlan.svg?component";
export default defineNuxtComponent({
	name: "ShoppingCartImage",

	props: {
		product: {
			type: Object,
			// Custom validator to ensure that either product or swapDevice is not null
			validator: function (value) {
				return value !== null || this.swapDevice !== null;
			},
			default: null,
		},
		swapDevice: {
			type: Object,
			// Custom validator to ensure that either swapDevice or product is not null
			validator: function (value) {
				return value !== null || this.product !== null;
			},
			default: null,
		},
	},

	components: {
		AllFallbackPhonePlan,
		Forsikring: defineAsyncComponent(() => import("@/assets/product-icons-svg/svg/AllForsikring.svg?component")),
		Plussforsikring: defineAsyncComponent(
			() => import("@/assets/product-icons-svg/svg/AllPlussforsikring.svg?component"),
		),
		SWAPPlussforsikring: defineAsyncComponent(
			() => import("@/assets/product-icons-svg/svg/AllPlussforsikring.svg?component"),
		),
		Safe: defineAsyncComponent(() => import("@/assets/product-icons-svg/svg/ConsumerSAFE.svg?component")),
		Samletplussforsikring: defineAsyncComponent(
			() => import("@/assets/product-icons-svg/svg/AllSamletPlussforsikring.svg?component"),
		),
		Skjermforsikring: defineAsyncComponent(
			() => import("@/assets/product-icons-svg/svg/AllSkjermforsikring.svg?component"),
		),
		SWAPSkjermforsikring: defineAsyncComponent(
			() => import("@/assets/product-icons-svg/svg/AllSkjermforsikring.svg?component"),
		),
		Svindelforsikring: defineAsyncComponent(
			() => import("@/assets/product-icons-svg/svg/ConsumerSAFESvindelforsikring.svg?component"),
		),
	},

	data() {
		return {
			imageLoading: true,
		};
	},

	computed: {
		agreement() {
			if (this.product?.type === "agreement") {
				return this.getSafeName(this.product);
			}
			return false;
		},
		ecommerceUrl() {
			return this.product?.metadata?.link?.to;
		},
		imageSrc() {
			return this.product?.metadata?.image;
		},
	},

	methods: {
		getSafeName(item) {
			const safeName = item?.metadata?.name?.replace("-", "")?.replaceAll(" ", "");
			return safeName === "Klokkeforsikring"
				? "Forsikring"
				: safeName === "SkjermforsikringSWAPNettbrett"
					? "SWAPPlussforsikring"
					: safeName === "PlussforsikringNettbrett"
						? "Plussforsikring"
						: safeName === "PlussforsikringSWAPNettbrett"
							? "SWAPPlussforsikring"
							: safeName;
		},
	},
});
</script>
<style lang="scss" scoped>
.product-image {
	width: 75px;
	height: 70px;
	margin-right: $spacing-m;
	display: grid;
	place-items: center;

	a {
		width: 75px;
		height: 70px;
		display: grid;
		place-items: center;
	}

	svg,
	img {
		object-fit: contain;
		max-height: 70px;
		max-width: 75px;
	}

	a:focus-visible {
		outline: 2px solid $color-cta-default;
		border-radius: $spacing-m;
		outline-offset: 3px;
	}
}
</style>
